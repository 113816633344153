






























































































































































































































































































































































































































































































































































































import Vue from "vue";    

var _self = this;
export default Vue.extend({
    name: "MaterialCard",
    components: {
        Viewer3D: () => import("./Viewer3D.component.vue"),
        ViewerDialog: () => import("./ViewerDialog.component.vue"),
    },
    data() {            
        return {                
            dialog: true,
            showEdit: false,
            materialBackground: "orange",
            materialName: "",
            materialType: "",
            materialClass: "",
            materialCardClass: "",


            timberStructuralDetailsSectionCollapsed: true,
            concreteStructuralDetailsSectionCollapsed: true,
            steelStructuralDetailsSectionCollapsed: true,


            containerParent: null,
            cardId: null,                
            ratingStyle: {
                color: "#05d",
            },
            ratingColor: [
                '#FFD700',
                '#C0C0C0',
                '#cd7f32'
            ],
            ratingLookup: [
                {
                    "Bay Weight": 0,
                    "Structural Depth": 1,
                },
            ],
            currentMenuTimberType: { text: "", textVal: "" },
            informationImg: require('@/assets/information-icon-white.png')
        };
    },
    watch: {
        '$store.state.materials.optionSystemTypeSelected'(val) {
            if (!this.currentMenuTimberType) { return; }
            var currentTimber = this.currentMenuTimberType.textVal ? this.currentMenuTimberType.textVal : this.currentMenuTimberType;
            if (val && val !== currentTimber) {
                this.currentMenuTimberType = this.getMenuTimberType(val);
                if (this.currentMenuTimberType) {
                    this.timberChanged(this.currentMenuTimberType);
                }
            }
        },
        currentMenuTimberType(val) {
            if (val && val.textVal && val.textVal.length > 0) {
                this.$store.state.materials.timberTypeSelection = val.textVal;
            }
        }
    },
    async mounted() {
        try {
            await Promise.resolve(this.define());
            await this.$nextTick(function () {              
            });
        } catch (error) {
            console.log(error);
        } finally {}
    },
    async beforeDestroy() { },
    computed: {            
        infoLink() {
            return `${this.$timberURL}/user-guide`;
        },
        materialTypes() {                
            return this.$store.getters["materials/getMaterialTypes"];
        },
        timberNames() {
            try {                    
                var timberList = this.$store.getters["materials/getTimberNames"];
                if (!this.currentMenuTimberType || this.currentMenuTimberType.text === "") {
                    this.currentMenuTimberType = timberList[0];
                }                    
                return timberList;
            } catch (error) {
                console.log(error);
            } finally {
            }                
        },
        materialNames() {
            var value = this.$store.getters["materials/getMaterialNames"](
                this.materialType
            );
            return value;
        },
        materialValueByKey() {
            try {
                return (key) => {
                    var value = this.$store.getters["materials/getMaterialValueByKey"](
                        this.materialType,
                        key
                    );
                    if (value === '') {
                        value = 'N/A'
                    }
                    return value;
                };
            } catch (error) {
               console.log(error);                    
            } finally {
            }
        },
        materialRatingByKey() {
            return (key) => {
                var rating = this.$store.getters["materials/getMaterialRatingByKey"](
                    this.materialType,
                    key
                );
                this.ratingLookup[0][key] = rating - 1;
                return rating;
            };
        }            
    },
    methods: {            
        getMenuTimberType(text) {
            var timberList = this.$store.getters["materials/getTimberNames"];
            return timberList.find(t => t.textVal === text);
        },
        async recalculate() {
            if (this.$store.state.materials.isAutoSelectEnabled) {
                await this.$root.$emit("onRecalculate");                           
            }                
        },
        isHiddenRow(materialValue){
            if(materialValue=='N/A'||materialValue==''){
                return true;
            }else{
                return false;
            }

        },

        closeDialog() {
            this.showEdit = false;
        },
        getMaterialRatingIcon(key) {
            var rating = this.materialRatingByKey(key);
            return (rating === 1) ? 'mdi-crown' : (rating === 2) ? 'mdi-numeric-2' : 'mdi-numeric-3';
        },
        getMaterialRatingContent(key) {
            var rating = this.materialRatingByKey(key);
            return (rating === 1) ? undefined : rating;
        },
        getMaterialRatingIconClass(key) {
            var rating = this.materialRatingByKey(key);
            return 'color-' + rating;
        },       
        getMaterialRatingTooltip(key) {
            var rating = this.materialRatingByKey(key);
            if (rating === 1) {
                return 'Top ranked';
            } else if (rating === 2) {
                return 'Second ranked';
            } else if (rating == 3) {
                return 'Third ranked';
            } else {
                return null;
            }              
        },       
        define: async function () {
            try {
                this.cardId = this.$attrs.id;
                switch (this.cardId) {
                    case "timberCard":
                        await Promise.resolve((this.materialName = "timber"));
                        await Promise.resolve((this.materialType = "Timber"));
                        await Promise.resolve((this.materialClass = "materialTimber"));
                        await Promise.resolve((this.materialCardClass = "timber-card"));
                        this.$root.$on(
                            "setTimberBestActive",
                            this.$parent.$refs.refTimberCard.setTimberBestActive
                        );
                        break;
                    case "concreteCard":
                        await Promise.resolve((this.materialName = "concrete"));
                        await Promise.resolve((this.materialType = "Concrete"));
                        await Promise.resolve((this.materialClass = "materialConcrete"));
                        await Promise.resolve(
                            (this.materialBackground =
                                "linear-gradient(to bottom, #5577A0, #1D415B)")
                        );
                        await Promise.resolve((this.materialCardClass = "concrete-card"));
                        break;
                    case "steelCard":
                        await Promise.resolve((this.materialName = "steel"));
                        await Promise.resolve((this.materialType = "Steel"));
                        await Promise.resolve((this.materialClass = "materialSteel"));
                        await Promise.resolve(
                            (this.materialBackground =
                                "linear-gradient(to bottom, #7C559E, #1D425B)")
                        );
                        await Promise.resolve((this.materialCardClass = "steel-card"));
                        break;
                }
            } catch (error) {
                console.log(error);                    
            } finally {
            }
        },
        setTimberBestActive() {
            try {
                if (this.$store.state.materials.isAutoSelectEnabled) {
                    this.currentMenuTimberType = this.$store.getters['materials/getBestTimberMaterial'];
                }
            } catch (error) {
                console.log(error);
            }
        },
        async timberChanged(value) {                
            this.$store.state.materials.isAutoSelectEnabled = false;
            try {
                await Promise.resolve(
                    this.$store.dispatch("materials/setTimberMaterial", [value])
                );
                // load data for controls
                await Promise.resolve(
                    this.$store.dispatch("materials/setItemRange", ["BayUnitWeight"])
                );
                await Promise.resolve(
                    this.$store.dispatch("materials/setItemRange", [
                        "OverallStructuralDepth",
                    ])
                );
                await Promise.resolve(
                    this.$store.dispatch("materials/setItemRange", ["Cost"])
                );
                await Promise.resolve(
                    this.$store.dispatch("materials/setItemRange", ["Carbon"])
                );

                await Promise.resolve(
                    this.$store.dispatch("materials/updateMaterialDetails", ["Timber"])
                );
                await Promise.resolve(
                    this.$store.dispatch("materials/setMaterialUri", ["Timber"])
                );

        // set chart data
        await Promise.resolve(
          this.$store.dispatch("materials/setChartDataByMaterialType", [
            "Timber",
          ])
        );
        await Promise.resolve(
          this.$store.dispatch("materials/setChartDataByMaterialType", [
            "Concrete",
          ])
        );
        await Promise.resolve(
          this.$store.dispatch("materials/setChartDataByMaterialType", [
            "Steel",
          ])
        );

                await Promise.resolve(this.$root.$emit("clearMaterialObject", "Timber", "cardTimber3D"))



      } catch (error) {
        console.log(error);            
      } finally {
          this.$root.$emit("updateAssumptions", "Timber");
          this.$root.$emit("obtainMaterialObject", "Timber", "cardTimber3D");
          this.$root.$emit("refreshChart", "Timber");
          this.$root.$emit("updateChart");
      }
    },
    async materialViewChanged(value) {
      try {
        this.$root.$emit("changeViewMaterial", value);
      } catch (error) {
        console.log(error);
      } finally {
      }
    },

        async refreshValues(valueChange, propChange) {
            // update local variables
            if (typeof valueChange === "string") {
                // when text
                eval("this." + propChange + " = " + "'" + valueChange + "'");
            } else {
                // when non-string
                eval("this." + propChange + " = " + valueChange.toString());
            }
        },
        getDatabaseValues(materialType, materialName, bayX, bayY, sector) {
            // update the card
        },
        changeViewOrientation: async function (valueChange, propChange) {
            this.$root.$emit("changeViewOrientation", valueChange, propChange);
        },
    },
});
