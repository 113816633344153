var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "mx-4",
      class: _vm.materialCardClass,
      attrs: { elevation: "15" }
    },
    [
      _c("v-card-title", { attrs: { "primary-title": "" } }, [
        _c(
          "div",
          {
            staticClass: "content",
            staticStyle: { "margin-bottom": "0px !important" }
          },
          [
            _c("p", { staticClass: "title" }, [
              _vm._v(_vm._s(_vm.materialName))
            ])
          ]
        ),
        _vm.materialType === "Timber"
          ? _c(
              "div",
              {
                staticStyle: { "padding-left": "0.5vw", "text-align": "right" }
              },
              [
                _c("v-checkbox", {
                  staticClass: "v-step-7a checkboxAutoSelect",
                  attrs: {
                    label: "auto",
                    dense: "",
                    dark: "",
                    "hide-details": "",
                    color: "white"
                  },
                  on: { click: _vm.recalculate },
                  model: {
                    value: _vm.$store.state.materials.isAutoSelectEnabled,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.$store.state.materials,
                        "isAutoSelectEnabled",
                        $$v
                      )
                    },
                    expression: "$store.state.materials.isAutoSelectEnabled"
                  }
                })
              ],
              1
            )
          : _vm._e(),
        _vm.materialType === "Timber"
          ? _c(
              "div",
              { attrs: { id: "timberAssumption" } },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "assumptions-btn",
                    attrs: {
                      elevation: "2",
                      icon: "",
                      small: "",
                      right: true,
                      absolute: true,
                      href: "" + _vm.infoLink,
                      target: "_blank"
                    }
                  },
                  [
                    _c("v-img", {
                      attrs: { src: _vm.informationImg, width: "24px" }
                    })
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e(),
        _vm.materialType === "Concrete"
          ? _c(
              "div",
              { attrs: { id: "concreteAssumption" } },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "assumptions-btn",
                    attrs: {
                      elevation: "2",
                      icon: "",
                      small: "",
                      right: true,
                      absolute: true,
                      href: "" + _vm.infoLink,
                      target: "_blank"
                    }
                  },
                  [
                    _c("v-img", {
                      attrs: { src: _vm.informationImg, width: "24px" }
                    })
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e(),
        _vm.materialType === "Steel"
          ? _c(
              "div",
              { attrs: { id: "steelAssumption" } },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "assumptions-btn",
                    attrs: {
                      elevation: "2",
                      icon: "",
                      small: "",
                      right: true,
                      absolute: true,
                      href: "" + _vm.infoLink,
                      target: "_blank"
                    }
                  },
                  [
                    _c("v-img", {
                      attrs: { src: _vm.informationImg, width: "24px" }
                    })
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e()
      ]),
      _vm.materialType === "Timber"
        ? _c(
            "div",
            [
              _c("v-select", {
                staticClass: "v-step-7 timber-select",
                attrs: {
                  items: _vm.timberNames,
                  "item-text": "text",
                  "item-value": "textVal",
                  dense: "",
                  outlined: "",
                  solo: "",
                  dark: "",
                  "single-line": "",
                  color: "#FFFFFF",
                  "item-color": "grey",
                  "background-color": "#00000061",
                  "return-object": "",
                  "hide-details": ""
                },
                on: {
                  input: function($event) {
                    return _vm.timberChanged(_vm.currentMenuTimberType)
                  }
                },
                model: {
                  value: _vm.currentMenuTimberType,
                  callback: function($$v) {
                    _vm.currentMenuTimberType = $$v
                  },
                  expression: "currentMenuTimberType"
                }
              })
            ],
            1
          )
        : _c("div", [
            _c(
              "div",
              {
                staticClass: "materialType",
                staticStyle: { "text-align": "left", overflow: "hidden" }
              },
              [
                _c("p", { staticClass: "pl-2", class: _vm.materialClass }, [
                  _vm._v(" " + _vm._s(_vm.materialNames) + " ")
                ])
              ]
            )
          ]),
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _vm.materialValueByKey("Cost") === "N/A" ||
          _vm.materialValueByKey("Cost") === "$ 0/m²"
            ? _c(
                "div",
                { staticStyle: { width: "100%" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "material-content",
                      staticStyle: { width: "100%" },
                      attrs: { cols: "12" }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "content",
                          staticStyle: {
                            width: "100%",
                            "padding-top": "42px",
                            height: "100%",
                            opacity: "0.8"
                          }
                        },
                        [
                          _c("img", {
                            attrs: {
                              src:
                                "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAABmJLR0QA/wD/AP+gvaeTAAADoUlEQVR4nO3czYoUVxyG8fc4oFHCZKFk4x0ENQmuXWg2IjHLxIULwU12EdcxkdyEEEc0oOAFmKxmMgtNgh+ooNkGN+pCEwJ+kBmm82RRLQyt3XVO21X1n9T7W84cuk6fh5qqak6PZLEB88AS8EnXc+m9YYzfqLxwlA6NxMBROjQmhqN0BVgcE2N9lP1dz7M3gH3As4woPlPa4igBOUpAjhKQowTkKAE5SkAFUQ50PdfecJSAHCUgRwnIUQJylIAcJSBHCchRAnKUgBwlIEcJyFHeTmriRak2RFyRtG3CsJeSTkr6q4k5dGRV0mNJt1NKa60cEZgDvgA21YzbPzwT+uhP4Ftga9Mx5oFrw4Oex1Hq3AR2NBVjE/DjyAEdpd4SMPtLA3B4zAEdpd7nues8cSFHfDnm58cknWNClJTSsqRPVV3I++hY7sCSILtrDngJmBs3YBjloKTnBcf8v9ibO7AkyPs1vz8iaaHmTLkq6TP170x5L3dgSZDHGWP85+vNHuUOLAmylDnOUV63mDsw+3YM2C3pjqSx14kRlyUdTSkNJrzmPkk/SXo3dx4b0EDShyml33MGZ58hKaV7kr4rmIivKZXTuTGKAQk4BawV3IP39TllDfiaJh4K37CAu4CzwB/ASsbk+hJlhWpNvgc+aDxE0/Be4ngcJSBHCchRAnKUgBwlIEcJyFECcpSAHCUgRwnIUQJylIAcJSBHCchRAnKUgBwlIEcJqFdRgD3AAvAAWK15002JuHFilWpNFqj2sTUeIgGngUGLb3KSiFFeGQDf0OQ2IKoY0USOAnCqZI1Lt5LeVdl+4LZE3rY6kPRRSul+zuCSxT1ROL5Nkbetzkn6KndwyQJHvxuJvOs+e+1K/mT9I2nLVNNp1wVJx1NK/44bQN736GdpJaX0Ts7AkjPk2ZSTaVvEr9f9nTuwJMitKSbSlSOSLtZEuSrpkNqJkr12JUEuTDGRLkW60P8w81ekeij8uaN7+bfR9XPKIk09HAI7gBsNTbxJXUW5DmxvJMa6iW+l+kjg6Ywn37Q2ozyh+vZU1p3VelOfSlQXzI8l7ZS0edrXadkvKaWJX1Em/5b4jKTlkZ+tSnoo6c6kTw2sEH366H6jcJSAHCUgRwnIUQJylIAcJSBHCchRAnKUgBwlIEcJyFECcpSAHCUgRwnIUQJylIAcJSBHCYi83SzZ/47cZqDmTPkVmO96jr0zJopjdGkkimNEMLymLDrGBvAfDxtj3yU1U0QAAAAASUVORK5CYII="
                            }
                          }),
                          _c(
                            "p",
                            {
                              staticClass: "sectionLabel",
                              staticStyle: {
                                width: "100%",
                                "text-align": "center"
                              }
                            },
                            [_vm._v("Option Not Applicable")]
                          )
                        ]
                      )
                    ]
                  )
                ],
                1
              )
            : _c(
                "div",
                { staticStyle: { width: "100%" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "material-content", attrs: { cols: "12" } },
                    [
                      _c("div", { staticClass: "content" }, [
                        _c("div", { staticClass: "v-step-6" }, [
                          _c("p", { staticClass: "sectionLabel" }, [
                            _vm._v(" Performance ")
                          ]),
                          _c("ul", { staticClass: "toc sectionDetail" }, [
                            _c(
                              "li",
                              { staticClass: "ratingRow" },
                              [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { top: "" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "div",
                                              _vm._g(
                                                _vm._b(
                                                  { staticClass: "itemDetail" },
                                                  "div",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c(
                                                  "v-badge",
                                                  {
                                                    class: _vm.getMaterialRatingIconClass(
                                                      "Bay Weight"
                                                    ),
                                                    attrs: {
                                                      icon: _vm.getMaterialRatingIcon(
                                                        "Bay Weight"
                                                      ),
                                                      color: "transparent",
                                                      overlap: "",
                                                      "offset-x": "-1"
                                                    }
                                                  },
                                                  [_vm._v(" Bay Weight ")]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ])
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getMaterialRatingTooltip(
                                            "Bay Weight"
                                          )
                                        )
                                      )
                                    ])
                                  ]
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass: "itemValue",
                                    staticStyle: { "margin-left": "0.25em" }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.materialValueByKey("Bay Weight")
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ]),
                          _c("ul", { staticClass: "toc sectionDetail" }, [
                            _c(
                              "li",
                              { staticClass: "ratingRow" },
                              [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { top: "" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "div",
                                              _vm._g(
                                                _vm._b(
                                                  { staticClass: "itemDetail" },
                                                  "div",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c(
                                                  "v-badge",
                                                  {
                                                    class: _vm.getMaterialRatingIconClass(
                                                      "Structural Depth"
                                                    ),
                                                    attrs: {
                                                      icon: _vm.getMaterialRatingIcon(
                                                        "Structural Depth"
                                                      ),
                                                      color: "transparent",
                                                      overlap: "",
                                                      "offset-x": "-1"
                                                    }
                                                  },
                                                  [_vm._v(" Structural Depth ")]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ])
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getMaterialRatingTooltip(
                                            "Structural Depth"
                                          )
                                        )
                                      )
                                    ])
                                  ]
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass: "itemValue",
                                    staticStyle: { "margin-left": "0.25em" }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.materialValueByKey(
                                            "Structural Depth"
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ]),
                          _c("ul", { staticClass: "toc sectionDetail" }, [
                            _c(
                              "li",
                              { staticClass: "ratingRow" },
                              [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { top: "" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "div",
                                              _vm._g(
                                                _vm._b(
                                                  { staticClass: "itemDetail" },
                                                  "div",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c(
                                                  "v-badge",
                                                  {
                                                    class: _vm.getMaterialRatingIconClass(
                                                      "Cost"
                                                    ),
                                                    attrs: {
                                                      icon: _vm.getMaterialRatingIcon(
                                                        "Cost"
                                                      ),
                                                      color: "transparent",
                                                      overlap: "",
                                                      "offset-x": "-1"
                                                    }
                                                  },
                                                  [_vm._v(" Cost ")]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ])
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getMaterialRatingTooltip("Cost")
                                        )
                                      )
                                    ])
                                  ]
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass: "itemValue",
                                    staticStyle: { "margin-left": "0.25em" }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.materialValueByKey("Cost")) +
                                        " "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ]),
                          _c("ul", { staticClass: "toc sectionDetail" }, [
                            _c(
                              "li",
                              { staticClass: "ratingRow" },
                              [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { top: "" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "div",
                                              _vm._g(
                                                _vm._b(
                                                  { staticClass: "itemDetail" },
                                                  "div",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c(
                                                  "v-badge",
                                                  {
                                                    class: _vm.getMaterialRatingIconClass(
                                                      "Carbon"
                                                    ),
                                                    attrs: {
                                                      icon: _vm.getMaterialRatingIcon(
                                                        "Carbon"
                                                      ),
                                                      color: "transparent",
                                                      overlap: "",
                                                      "offset-x": "-1"
                                                    }
                                                  },
                                                  [_vm._v(" Carbon ")]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ])
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getMaterialRatingTooltip("Carbon")
                                        )
                                      )
                                    ])
                                  ]
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass: "itemValue",
                                    staticStyle: { "margin-left": "0.25em" }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.materialValueByKey("Carbon")
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ])
                        ]),
                        _c("p", { staticClass: "sectionLabel" }, [
                          _vm._v(" Structural Details ")
                        ]),
                        _vm.materialType === "Timber"
                          ? _c(
                              "div",
                              [
                                _c(
                                  "div",
                                  {
                                    class: {
                                      cardSectionCollapsed:
                                        _vm.timberStructuralDetailsSectionCollapsed
                                    }
                                  },
                                  [
                                    _c(
                                      "ul",
                                      {
                                        staticClass: "toc sectionDetail",
                                        class: {
                                          hiderow: _vm.isHiddenRow(
                                            _vm.materialValueByKey("CLT Type")
                                          )
                                        }
                                      },
                                      [
                                        _c("li", [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "itemDetail",
                                              staticStyle: {
                                                "margin-right": "0.25em"
                                              }
                                            },
                                            [_vm._v("CLT Type")]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "itemValue",
                                              staticStyle: {
                                                "margin-left": "0.25em"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.materialValueByKey(
                                                      "CLT Type"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ])
                                      ]
                                    ),
                                    _c(
                                      "ul",
                                      {
                                        staticClass: "toc sectionDetail",
                                        class: {
                                          hiderow: _vm.isHiddenRow(
                                            _vm.materialValueByKey(
                                              "Thickness of Concrete"
                                            )
                                          )
                                        }
                                      },
                                      [
                                        _c("li", [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "itemDetail",
                                              staticStyle: {
                                                "margin-right": "0.25em"
                                              }
                                            },
                                            [_vm._v("Thickness of Concrete")]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "itemValue",
                                              staticStyle: {
                                                "margin-left": "0.25em"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.materialValueByKey(
                                                      "Thickness of Concrete"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ])
                                      ]
                                    ),
                                    _c(
                                      "ul",
                                      {
                                        staticClass: "toc sectionDetail",
                                        class: {
                                          hiderow: _vm.isHiddenRow(
                                            _vm.materialValueByKey(
                                              "Thickness of CLT"
                                            )
                                          )
                                        }
                                      },
                                      [
                                        _c("li", [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "itemDetail",
                                              staticStyle: {
                                                "margin-right": "0.25em"
                                              }
                                            },
                                            [_vm._v("Thickness of CLT")]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "itemValue",
                                              staticStyle: {
                                                "margin-left": "0.25em"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.materialValueByKey(
                                                      "Thickness of CLT"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ])
                                      ]
                                    ),
                                    _c(
                                      "ul",
                                      {
                                        staticClass: "toc sectionDetail",
                                        class: {
                                          hiderow: _vm.isHiddenRow(
                                            _vm.materialValueByKey(
                                              "Grade of CLT"
                                            )
                                          )
                                        }
                                      },
                                      [
                                        _c("li", [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "itemDetail",
                                              staticStyle: {
                                                "margin-right": "0.25em"
                                              }
                                            },
                                            [_vm._v("Grade of CLT")]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "itemValue",
                                              staticStyle: {
                                                "margin-left": "0.25em"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.materialValueByKey(
                                                      "Grade of CLT"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ])
                                      ]
                                    ),
                                    _c(
                                      "ul",
                                      {
                                        staticClass: "toc sectionDetail",
                                        class: {
                                          hiderow: _vm.isHiddenRow(
                                            _vm.materialValueByKey(
                                              "Glulam Purlin Size"
                                            )
                                          )
                                        }
                                      },
                                      [
                                        _c("li", [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "itemDetail",
                                              staticStyle: {
                                                "margin-right": "0.25em"
                                              }
                                            },
                                            [_vm._v("Glulam Purlin Size")]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "itemValue",
                                              staticStyle: {
                                                "margin-left": "0.25em"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.materialValueByKey(
                                                      "Glulam Purlin Size"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ])
                                      ]
                                    ),
                                    _c(
                                      "ul",
                                      {
                                        staticClass: "toc sectionDetail",
                                        class: {
                                          hiderow: _vm.isHiddenRow(
                                            _vm.materialValueByKey(
                                              "Glulam Girder Size"
                                            )
                                          )
                                        }
                                      },
                                      [
                                        _c("li", [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "itemDetail",
                                              staticStyle: {
                                                "margin-right": "0.25em"
                                              }
                                            },
                                            [_vm._v("Glulam Girder Size")]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "itemValue",
                                              staticStyle: {
                                                "margin-left": "0.25em"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.materialValueByKey(
                                                      "Glulam Girder Size"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ])
                                      ]
                                    ),
                                    _c(
                                      "ul",
                                      {
                                        staticClass: "toc sectionDetail",
                                        class: {
                                          hiderow: _vm.isHiddenRow(
                                            _vm.materialValueByKey(
                                              "Glulam Grade"
                                            )
                                          )
                                        }
                                      },
                                      [
                                        _c("li", [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "itemDetail",
                                              staticStyle: {
                                                "margin-right": "0.25em"
                                              }
                                            },
                                            [_vm._v("Glulam Grade")]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "itemValue",
                                              staticStyle: {
                                                "margin-left": "0.25em"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.materialValueByKey(
                                                      "Glulam Grade"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ])
                                      ]
                                    ),
                                    _c(
                                      "ul",
                                      {
                                        staticClass: "toc sectionDetail",
                                        class: {
                                          hiderow: _vm.isHiddenRow(
                                            _vm.materialValueByKey(
                                              "Steel Purlin Size"
                                            )
                                          )
                                        }
                                      },
                                      [
                                        _c("li", [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "itemDetail",
                                              staticStyle: {
                                                "margin-right": "0.25em"
                                              }
                                            },
                                            [_vm._v("Steel Purlin Size")]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "itemValue",
                                              staticStyle: {
                                                "margin-left": "0.25em"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.materialValueByKey(
                                                      "Steel Purlin Size"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ])
                                      ]
                                    ),
                                    _c(
                                      "ul",
                                      {
                                        staticClass: "toc sectionDetail",
                                        class: {
                                          hiderow: _vm.isHiddenRow(
                                            _vm.materialValueByKey(
                                              "Steel Girder Size"
                                            )
                                          )
                                        }
                                      },
                                      [
                                        _c("li", [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "itemDetail",
                                              staticStyle: {
                                                "margin-right": "0.25em"
                                              }
                                            },
                                            [_vm._v("Steel Girder Size")]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "itemValue",
                                              staticStyle: {
                                                "margin-left": "0.25em"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.materialValueByKey(
                                                      "Steel Girder Size"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ])
                                      ]
                                    ),
                                    _c(
                                      "ul",
                                      {
                                        staticClass: "toc sectionDetail",
                                        class: {
                                          hiderow: _vm.isHiddenRow(
                                            _vm.materialValueByKey(
                                              "Girder Support"
                                            )
                                          )
                                        }
                                      },
                                      [
                                        _c("li", [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "itemDetail",
                                              staticStyle: {
                                                "margin-right": "0.25em"
                                              }
                                            },
                                            [_vm._v("Girder Support")]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "itemValue",
                                              staticStyle: {
                                                "margin-left": "0.25em"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.materialValueByKey(
                                                      "Girder Support"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ])
                                      ]
                                    ),
                                    _c(
                                      "ul",
                                      {
                                        staticClass: "toc sectionDetail",
                                        class: {
                                          hiderow: _vm.isHiddenRow(
                                            _vm.materialValueByKey(
                                              "Governing Condition"
                                            )
                                          )
                                        }
                                      },
                                      [
                                        _c("li", [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "itemDetail",
                                              staticStyle: {
                                                "margin-right": "0.25em"
                                              }
                                            },
                                            [_vm._v("Governing Condition")]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "itemValue",
                                              staticStyle: {
                                                "margin-left": "0.25em"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.materialValueByKey(
                                                      "Governing Condition"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ])
                                      ]
                                    ),
                                    _c(
                                      "ul",
                                      {
                                        staticClass: "toc sectionDetail",
                                        class: {
                                          hiderow: _vm.isHiddenRow(
                                            _vm.materialValueByKey("Vibration")
                                          )
                                        }
                                      },
                                      [
                                        _c("li", [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "itemDetail",
                                              staticStyle: {
                                                "margin-right": "0.25em"
                                              }
                                            },
                                            [_vm._v("Vibration")]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "itemValue",
                                              staticStyle: {
                                                "margin-left": "0.25em"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.materialValueByKey(
                                                      "Vibration"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ])
                                      ]
                                    )
                                  ]
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass:
                                      "d-none d-lg-block button-moreless",
                                    attrs: {
                                      color: "white",
                                      dark: "",
                                      plain: "",
                                      hidden: !_vm.timberStructuralDetailsSectionCollapsed
                                    },
                                    on: {
                                      click: function($event) {
                                        _vm.timberStructuralDetailsSectionCollapsed = !_vm.timberStructuralDetailsSectionCollapsed
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.timberStructuralDetailsSectionCollapsed
                                          ? "show more >>"
                                          : "<< show less"
                                      )
                                    )
                                  ]
                                ),
                                _c("p", { staticClass: "sectionLabel" }, [
                                  _vm._v(" Quantities ")
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass: "qtysection-detail-container"
                                  },
                                  [
                                    _c(
                                      "ul",
                                      { staticClass: "toc sectionDetail" },
                                      [
                                        _c("li", [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "itemDetail",
                                              staticStyle: {
                                                "margin-right": "0.25em"
                                              }
                                            },
                                            [_vm._v("Timber Volume")]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "itemValue",
                                              staticStyle: {
                                                "margin-left": "0.25em"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.materialValueByKey(
                                                      "Timber Volume"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ])
                                      ]
                                    ),
                                    _c(
                                      "ul",
                                      { staticClass: "toc sectionDetail" },
                                      [
                                        _c("li", [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "itemDetail",
                                              staticStyle: {
                                                "margin-right": "0.25em"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "Steel Joist and Girder Tonnage"
                                              )
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "itemValue",
                                              staticStyle: {
                                                "margin-left": "0.25em"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.materialValueByKey(
                                                      "Steel Joist and Girder Tonnage"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ])
                                      ]
                                    ),
                                    _c(
                                      "ul",
                                      { staticClass: "toc sectionDetail" },
                                      [
                                        _c("li", [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "itemDetail",
                                              staticStyle: {
                                                "margin-right": "0.25em"
                                              }
                                            },
                                            [_vm._v("Concrete Volume")]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "itemValue",
                                              staticStyle: {
                                                "margin-left": "0.25em"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.materialValueByKey(
                                                      "Concrete Volume"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ])
                                      ]
                                    ),
                                    _c(
                                      "ul",
                                      { staticClass: "toc sectionDetail" },
                                      [
                                        _c("li", [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "itemDetail",
                                              staticStyle: {
                                                "margin-right": "0.25em"
                                              }
                                            },
                                            [_vm._v("Concrete Reinforcement")]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "itemValue",
                                              staticStyle: {
                                                "margin-left": "0.25em"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.materialValueByKey(
                                                      "Concrete Reinforcement"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ])
                                      ]
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          : _vm.materialType === "Concrete"
                          ? _c(
                              "div",
                              [
                                _c(
                                  "div",
                                  {
                                    class: {
                                      cardSectionCollapsed:
                                        _vm.concreteStructuralDetailsSectionCollapsed
                                    }
                                  },
                                  [
                                    _c(
                                      "ul",
                                      {
                                        staticClass: "toc sectionDetail",
                                        class: {
                                          hiderow: _vm.isHiddenRow(
                                            _vm.materialValueByKey(
                                              "Slab Thickness"
                                            )
                                          )
                                        }
                                      },
                                      [
                                        _c("li", [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "itemDetail",
                                              staticStyle: {
                                                "margin-right": "0.25em"
                                              }
                                            },
                                            [_vm._v("Slab Thickness")]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "itemValue",
                                              staticStyle: {
                                                "margin-left": "0.25em"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.materialValueByKey(
                                                      "Slab Thickness"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ])
                                      ]
                                    ),
                                    _c(
                                      "ul",
                                      {
                                        staticClass: "toc sectionDetail",
                                        class: {
                                          hiderow: _vm.isHiddenRow(
                                            _vm.materialValueByKey(
                                              "Drop Panel Size"
                                            )
                                          )
                                        }
                                      },
                                      [
                                        _c("li", [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "itemDetail",
                                              staticStyle: {
                                                "margin-right": "0.25em"
                                              }
                                            },
                                            [_vm._v("Drop Panel Size")]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "itemValue",
                                              staticStyle: {
                                                "margin-left": "0.25em"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.materialValueByKey(
                                                      "Drop Panel Size"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ])
                                      ]
                                    ),
                                    _c(
                                      "ul",
                                      {
                                        staticClass: "toc sectionDetail",
                                        class: {
                                          hiderow: _vm.isHiddenRow(
                                            _vm.materialValueByKey(
                                              "Drop Panel Thickness"
                                            )
                                          )
                                        }
                                      },
                                      [
                                        _c("li", [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "itemDetail",
                                              staticStyle: {
                                                "margin-right": "0.25em"
                                              }
                                            },
                                            [_vm._v("Drop Panel Thickness")]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "itemValue",
                                              staticStyle: {
                                                "margin-left": "0.25em"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.materialValueByKey(
                                                      "Drop Panel Thickness"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ])
                                      ]
                                    ),
                                    _c(
                                      "ul",
                                      {
                                        staticClass: "toc sectionDetail",
                                        class: {
                                          hiderow: _vm.isHiddenRow(
                                            _vm.materialValueByKey(
                                              "Governing Condition"
                                            )
                                          )
                                        }
                                      },
                                      [
                                        _c("li", [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "itemDetail",
                                              staticStyle: {
                                                "margin-right": "0.25em"
                                              }
                                            },
                                            [_vm._v("Governing Condition")]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "itemValue",
                                              staticStyle: {
                                                "margin-left": "0.25em"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.materialValueByKey(
                                                      "Governing Condition"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ])
                                      ]
                                    )
                                  ]
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass:
                                      "d-none d-lg-block button-moreless",
                                    attrs: {
                                      color: "white",
                                      dark: "",
                                      plain: "",
                                      hidden: !_vm.concreteStructuralDetailsSectionCollapsed
                                    },
                                    on: {
                                      click: function($event) {
                                        _vm.concreteStructuralDetailsSectionCollapsed = !_vm.concreteStructuralDetailsSectionCollapsed
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.concreteStructuralDetailsSectionCollapsed
                                          ? "show more >>"
                                          : "<< show less"
                                      )
                                    )
                                  ]
                                ),
                                _c("p", { staticClass: "sectionLabel" }, [
                                  _vm._v(" Quantities ")
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass: "qtysection-detail-container"
                                  },
                                  [
                                    _c(
                                      "ul",
                                      { staticClass: "toc sectionDetail" },
                                      [
                                        _c("li", [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "itemDetail",
                                              staticStyle: {
                                                "margin-right": "0.25em"
                                              }
                                            },
                                            [_vm._v("Concrete Volume")]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "itemValue",
                                              staticStyle: {
                                                "margin-left": "0.25em"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.materialValueByKey(
                                                      "Concrete Volume"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ])
                                      ]
                                    ),
                                    _c(
                                      "ul",
                                      { staticClass: "toc sectionDetail" },
                                      [
                                        _c("li", [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "itemDetail",
                                              staticStyle: {
                                                "margin-right": "0.25em"
                                              }
                                            },
                                            [_vm._v("Formwork Quantity")]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "itemValue",
                                              staticStyle: {
                                                "margin-left": "0.25em"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.materialValueByKey(
                                                      "Formwork Quantity"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ])
                                      ]
                                    ),
                                    _c(
                                      "ul",
                                      { staticClass: "toc sectionDetail" },
                                      [
                                        _c("li", [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "itemDetail",
                                              staticStyle: {
                                                "margin-right": "0.25em"
                                              }
                                            },
                                            [_vm._v("Concrete Reinforcement")]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "itemValue",
                                              staticStyle: {
                                                "margin-left": "0.25em"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.materialValueByKey(
                                                      "Concrete Reinforcement"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ])
                                      ]
                                    )
                                  ]
                                ),
                                _c("ul", { staticClass: "sectionDetail" }, [
                                  _c("li", [
                                    _c("span", {
                                      staticClass: "itemDetail",
                                      staticStyle: { "margin-right": "0.25em" }
                                    }),
                                    _c("span", {
                                      staticClass: "itemValue",
                                      staticStyle: { "margin-left": "0.25em" }
                                    })
                                  ])
                                ])
                              ],
                              1
                            )
                          : _vm.materialType === "Steel"
                          ? _c(
                              "div",
                              [
                                _c(
                                  "div",
                                  {
                                    class: {
                                      cardSectionCollapsed:
                                        _vm.steelStructuralDetailsSectionCollapsed
                                    }
                                  },
                                  [
                                    _c(
                                      "ul",
                                      {
                                        staticClass: "toc sectionDetail",
                                        class: {
                                          hiderow: _vm.isHiddenRow(
                                            _vm.materialValueByKey(
                                              "Thickness of Concrete"
                                            )
                                          )
                                        }
                                      },
                                      [
                                        _c("li", [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "itemDetail",
                                              staticStyle: {
                                                "margin-right": "0.25em"
                                              }
                                            },
                                            [_vm._v("Thickness of Concrete")]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "itemValue",
                                              staticStyle: {
                                                "margin-left": "0.25em"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.materialValueByKey(
                                                      "Thickness of Concrete"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ])
                                      ]
                                    ),
                                    _c(
                                      "ul",
                                      {
                                        staticClass: "toc sectionDetail",
                                        class: {
                                          hiderow: _vm.isHiddenRow(
                                            _vm.materialValueByKey(
                                              "Floor Deck Type"
                                            )
                                          )
                                        }
                                      },
                                      [
                                        _c("li", [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "itemDetail",
                                              staticStyle: {
                                                "margin-right": "0.25em"
                                              }
                                            },
                                            [_vm._v("Floor Deck Type")]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "itemValue",
                                              staticStyle: {
                                                "margin-left": "0.25em",
                                                width: "50% !important",
                                                "text-overflow": "ellipsis",
                                                overflow: "hidden"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.materialValueByKey(
                                                    "Floor Deck Type"
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        ])
                                      ]
                                    ),
                                    _c(
                                      "ul",
                                      {
                                        staticClass: "toc sectionDetail",
                                        class: {
                                          hiderow: _vm.isHiddenRow(
                                            _vm.materialValueByKey(
                                              "Steel Joist Size"
                                            )
                                          )
                                        }
                                      },
                                      [
                                        _c("li", [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "itemDetail",
                                              staticStyle: {
                                                "margin-right": "0.25em"
                                              }
                                            },
                                            [_vm._v("Steel Joist Size")]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "itemValue",
                                              staticStyle: {
                                                "margin-left": "0.25em"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.materialValueByKey(
                                                      "Steel Joist Size"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ])
                                      ]
                                    ),
                                    _c(
                                      "ul",
                                      {
                                        staticClass: "toc sectionDetail",
                                        class: {
                                          hiderow: _vm.isHiddenRow(
                                            _vm.materialValueByKey(
                                              "Steel Girder Size"
                                            )
                                          )
                                        }
                                      },
                                      [
                                        _c("li", [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "itemDetail",
                                              staticStyle: {
                                                "margin-right": "0.25em"
                                              }
                                            },
                                            [_vm._v("Steel Girder Size")]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "itemValue",
                                              staticStyle: {
                                                "margin-left": "0.25em"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.materialValueByKey(
                                                      "Steel Girder Size"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ])
                                      ]
                                    ),
                                    _c(
                                      "ul",
                                      {
                                        staticClass: "toc sectionDetail",
                                        class: {
                                          hiderow: _vm.isHiddenRow(
                                            _vm.materialValueByKey(
                                              "Governing Condition"
                                            )
                                          )
                                        }
                                      },
                                      [
                                        _c("li", [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "itemDetail",
                                              staticStyle: {
                                                "margin-right": "0.25em"
                                              }
                                            },
                                            [_vm._v("Governing Condition")]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "itemValue",
                                              staticStyle: {
                                                "margin-left": "0.25em"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.materialValueByKey(
                                                      "Governing Condition"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ])
                                      ]
                                    )
                                  ]
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass:
                                      "d-none d-lg-block button-moreless",
                                    attrs: {
                                      color: "white",
                                      dark: "",
                                      plain: "",
                                      hidden: !_vm.steelStructuralDetailsSectionCollapsed
                                    },
                                    on: {
                                      click: function($event) {
                                        _vm.steelStructuralDetailsSectionCollapsed = !_vm.steelStructuralDetailsSectionCollapsed
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.steelStructuralDetailsSectionCollapsed
                                          ? "show more >>"
                                          : "<< show less"
                                      )
                                    )
                                  ]
                                ),
                                _c("p", { staticClass: "sectionLabel" }, [
                                  _vm._v(" Quantities ")
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass: "qtysection-detail-container"
                                  },
                                  [
                                    _c(
                                      "ul",
                                      { staticClass: "toc sectionDetail" },
                                      [
                                        _c("li", [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "itemDetail",
                                              staticStyle: {
                                                "margin-right": "0.25em"
                                              }
                                            },
                                            [_vm._v("Concrete Volume")]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "itemValue",
                                              staticStyle: {
                                                "margin-left": "0.25em"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.materialValueByKey(
                                                      "Concrete Volume"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ])
                                      ]
                                    ),
                                    _c(
                                      "ul",
                                      { staticClass: "toc sectionDetail" },
                                      [
                                        _c("li", [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "itemDetail",
                                              staticStyle: {
                                                "margin-right": "0.25em"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "Steel Joist and Girder Tonnage"
                                              )
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "itemValue",
                                              staticStyle: {
                                                "margin-left": "0.25em"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.materialValueByKey(
                                                      "Steel Joist and Girder Tonnage"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ])
                                      ]
                                    ),
                                    _c(
                                      "ul",
                                      { staticClass: "toc sectionDetail" },
                                      [
                                        _c("li", [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "itemDetail",
                                              staticStyle: {
                                                "margin-right": "0.25em"
                                              }
                                            },
                                            [_vm._v("Steel Deck Tonnage")]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "itemValue",
                                              staticStyle: {
                                                "margin-left": "0.25em"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.materialValueByKey(
                                                      "Steel Deck Tonnage"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ])
                                      ]
                                    ),
                                    _c(
                                      "ul",
                                      { staticClass: "toc sectionDetail" },
                                      [
                                        _c("li", [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "itemDetail",
                                              staticStyle: {
                                                "margin-right": "0.25em"
                                              }
                                            },
                                            [_vm._v("Concrete Reinforcement")]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "itemValue",
                                              staticStyle: {
                                                "margin-left": "0.25em"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.materialValueByKey(
                                                      "Concrete Reinforcement"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ])
                                      ]
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ])
                    ]
                  )
                ],
                1
              ),
          _c("v-col", { attrs: { cols: "12" } }, [
            _vm.materialType === "Timber"
              ? _c("div", [
                  _c(
                    "div",
                    {
                      staticClass: "divView",
                      staticStyle: { "text-align": "right" },
                      attrs: { id: "cardTimber3D" }
                    },
                    [
                      _c("Viewer3D", {
                        ref: "viewerId",
                        attrs: {
                          id: "viewerTimber3D",
                          parentId: "cardTimber3D"
                        }
                      }),
                      _c("ViewerDialog", {
                        ref: "viewerId",
                        attrs: {
                          dialog: _vm.showEdit,
                          id: "viewerDialog",
                          parentId: "cardTimber3D"
                        },
                        on: {
                          "update:dialog": function($event) {
                            _vm.showEdit = $event
                          },
                          closeDialog: _vm.closeDialog
                        }
                      })
                    ],
                    1
                  )
                ])
              : _vm.materialType === "Concrete"
              ? _c("div", [
                  _c(
                    "div",
                    {
                      staticClass: "divView v-step-8",
                      staticStyle: { "text-align": "right" },
                      attrs: { id: "cardConcrete3D" }
                    },
                    [
                      _c("Viewer3D", {
                        ref: "viewerId",
                        attrs: {
                          id: "viewerConcrete3D",
                          parentId: "cardConcrete3D"
                        }
                      }),
                      _c("ViewerDialog", {
                        ref: "viewerId",
                        attrs: {
                          dialog: _vm.showEdit,
                          id: "viewerDialog",
                          parentId: "cardConcrete3D"
                        },
                        on: {
                          "update:dialog": function($event) {
                            _vm.showEdit = $event
                          },
                          closeDialog: _vm.closeDialog
                        }
                      })
                    ],
                    1
                  )
                ])
              : _vm.materialType === "Steel"
              ? _c("div", [
                  _c(
                    "div",
                    {
                      staticClass: "divView",
                      staticStyle: { "text-align": "right" },
                      attrs: { id: "cardSteel3D" }
                    },
                    [
                      _c("Viewer3D", {
                        ref: "viewerId",
                        attrs: { id: "viewerSteel3D", parentId: "cardSteel3D" }
                      }),
                      _c("ViewerDialog", {
                        ref: "viewerId",
                        attrs: {
                          dialog: _vm.showEdit,
                          id: "viewerDialog",
                          parentId: "cardSteel3D"
                        },
                        on: {
                          "update:dialog": function($event) {
                            _vm.showEdit = $event
                          },
                          closeDialog: _vm.closeDialog
                        }
                      })
                    ],
                    1
                  )
                ])
              : _vm._e()
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }